.section {
  height: 100%;
  width: 100%;
  padding: 5px;
  position: relative;
}
.toolbar {
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  padding-right: 50px;
  width: 100%;
}
.container {
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
  margin: 30px 0;
}

.infoCard {
  display: flex;
  flex-direction: column;
  gap: 35px;
  width: 400px;
  height: 363px;
  background: var(--purpletronic-30);
  box-shadow: 10px 10px 35px 8px rgba(144, 144, 174, 0.3);
  border-radius: 16px;
  padding: 25px 35px;
}

.infoCardActions {
  display: flex;
  flex-direction: column;
  gap: 15px;
  margin: 0 auto;
  width: 80%;
}
